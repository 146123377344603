import { Suspense } from 'react';
import './App.css';
import 'bootstrap/scss/bootstrap.scss';
import './assets/css/main.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserInfo } from './stores';
import { useRecoilValue } from 'recoil';
import { Login } from './pages/Login';
import ReactLoading from 'react-loading';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Wrap } from './pages/Wrap';
import { Users } from './pages/Users';
import { Logs } from './pages/Logs';
import { Overview } from './pages/Overview';
import { Activities } from './pages/Activities';
import { SearchWeight } from './pages/SearchWeight';
import { Admins } from './pages/Admins';
import { StateGrant } from './pages/StateGrant';
import { SchoolList } from './pages/SchoolList';
import { Reports } from './pages/Reports';
import { SpringReports } from './pages/SpringReports';
const renderLoader = () => (
  <div className="w-100 text-center py-4">
    <ReactLoading
      type="spinningBubbles"
      className="mx-auto my-auto"
      height={'70px'}
      width={'70px'}
      color="#666666"
    />
  </div>
);
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 120, // 30seconds
        // refetchOnMount: 'always',
        // refetchOnReconnect: 'always',
        // refetchOnWindowFocus: 'always',
      },
    },
  });
  const userinfo = useRecoilValue(UserInfo);
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={renderLoader()}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            {userinfo && (
              <>
                <Route path="/home" element={<Wrap />}>
                  <Route path="users" element={<Users />} />
                  <Route path="overview" element={<Overview />} />
                  <Route path="admins" element={<Admins />} />
                  <Route path="state_grant" element={<StateGrant />} />
                  <Route path="school_list" element={<SchoolList />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="report_spring_term" element={<SpringReports />} />
                </Route>
              </>
            )}
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
