import React, { useEffect, useState } from 'react';
import { Container, Form, Modal, Spinner } from 'react-bootstrap';
import { UseGenerateLetterMail, UseGetDecodedSSN } from '../services/Query';
import checked from '../assets/images/checked.svg';
import { Student } from '../models/student';
interface Props {
  show: boolean;
  userId: string;
  userObj: Student;
  onOk: () => void;
  onDiscard: () => void;
}

export const RegenerateLetterConfirm = (props: Props) => {
  const { userId, onOk, onDiscard, userObj } = props;
  const { mutate: generateLetter, isLoading: isSending } =
    UseGenerateLetterMail();
  const generateLetterMail = () => {
    generateLetter(userId, {
      onSuccess: () => {
        onOk();
      },
    });
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title className="fs-5">Regenerate Letter</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <p className=" fs-6 fw-bold text-center">
            Are you sure you want to regenerate the letter to the student,
            parents/guardian, and financial aid officer?
          </p>
          <div className="d-flex justify-content-center pt-3">
            <button
              className="btn btn-success py-1 px-4 fs-5 ms-3 fw-bold"
              disabled={isSending}
              onClick={() => {
                generateLetterMail();
              }}
            >
              {isSending && (
                <Spinner animation="border" className="me-1" size="sm" />
              )}
              Yes
            </button>
            <button
              disabled={isSending}
              className="btn btn-secondary py-1 px-4 fs-5 ms-3 fw-bold"
              onClick={() => {
                onDiscard();
              }}
            >
              No
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
