import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { TopHeader } from '../components/TopHeader';
import { LoginRes, LoginUser, Member } from '../models';
import { useAdminSignin } from '../services/Query';
import TokenServices from '../services/TokenServices';
import { token, UserInfo } from '../stores';
import { useForm } from 'react-hook-form';
import { LoginConfirmModal } from '../components/LoginConfirmModal';
type userLoginFrm = {
  email: string;
  password: string;
};
export const Login = () => {
  const app_client_id = process.env.REACT_APP_CLIENT_ID;
  const navigate = useNavigate();
  const { isLoading: isSignin, mutate: onAdminSignin } = useAdminSignin();
  const [showLoginConfirmModal, setShowLoginConfirmModal] = useState(false);
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const {
    register,
    setValue,
    watch,
    reset,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<userLoginFrm>({ mode: 'onChange' });
  const onLogin = () => {
    const data = getValues();
    onAdminSignin(JSON.stringify(data), {
      onSuccess: (res) => {
        setUserinfo(res.member);
        navigate('/home/users');
      },
      onError: () => {
        setShowLoginConfirmModal(true);
      },
    });
  };
  return (
    <>
      <div className="w-100 d-flex align-items-center align-middle wrap_100">
        <Card className="sign_card mx-auto">
          <Card.Header className="fw-bold fs-6">Log in</Card.Header>
          <Card.Body className="align-items-center">
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  {...register('email', { required: true })}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  {...register('password', { required: true })}
                />
              </Form.Group>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <Button
              className="btn btn-success"
              disabled={!isValid}
              onClick={() => onLogin()}
            >
              {isSignin && (
                <Spinner animation="border" className="me-1" size="sm" />
              )}
              Login
            </Button>
          </Card.Footer>
        </Card>
        {showLoginConfirmModal && (
          <LoginConfirmModal
            show={showLoginConfirmModal}
            onOk={() => {
              setShowLoginConfirmModal(false);
            }}
            leftTitle="OK"
            content="Your credentials do not match. Please try it again."
          />
        )}
      </div>
    </>
  );
};
