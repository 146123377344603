import moment from "moment-timezone";

import { useState } from "react";
import { Card, Form, InputGroup, Spinner } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { UseSaveAdminData, useAdminList } from "../services/Query";
import { UserInfo } from "../stores";
import { useForm } from "react-hook-form";
import { AddUserModal } from "../components/modal/AddUserModal";
import { Admin, AdminLevel } from "../models/admin";
type FrmUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  level: number;
  password: string;
  re_password: string;
};
export const Admins = () => {
  const {
    isLoading: userLoading,
    data: users,
    isFetching: userFetching,
    error: errorLoading,
    refetch: getUsers,
  } = useAdminList();
  const {
    register,
    reset,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<FrmUser>({ mode: "onChange" });

  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const { password, re_password, id } = watch();

  const [isEdit, setIsEdit] = useState(false);
  const renderTime = (vTime: string) => {
    if (vTime.indexOf("0001") === 0) return "";
    const pos = vTime.indexOf(".");
    const rTime = vTime.substring(0, pos) + "-00:00";
    return moment.tz(rTime, "America/New_York").format("YYYY-MM-DD HH:mm:ss");
  };
  const { mutate: saveUser, isLoading: isSaving } = UseSaveAdminData();
  const [addUsers, setAddUsers] = useState(false);
  if (userLoading || userFetching) {
    return (
      <div className="w-100 text-center py-4 ">
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={"80px"}
          width={"80px"}
          color="#666666"
        />
      </div>
    );
  }

  const onRegUser = () => {
    const data = getValues();
    saveUser(JSON.stringify(data), {
      onSuccess: () => {
        getUsers();
        setIsEdit(false);
      },
      onError: () => {
        alert("error");
      },
    });
  };
  const onEditItem = (item: Admin) => {
    setIsEdit(true);
    reset({
      id: item.id,
      firstName: item.firstname,
      lastName: item.lastname,
      email: item.email,
      level: item.level,
    });
  };
  if (isEdit) {
    return (
      <div>
        <Card className="w-50">
          <Card.Header>add administrator</Card.Header>
          <Card.Body>
            <div className="d-grid gap-3">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register("firstName", { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register("lastName", { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Level</InputGroup.Text>
                <Form.Select {...register("level", { required: true })}>
                  <option value={0}>Regular</option>
                  <option value={1}>Master</option>
                  <option value={2}>Super</option>
                </Form.Select>
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register("email", { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>password</InputGroup.Text>
                <Form.Control type="password" {...register("password")} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>confirm password</InputGroup.Text>
                <Form.Control type="password" {...register("re_password")} />
              </InputGroup>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <button
              className="bg-white rounded-2 px-3"
              disabled={
                !isValid ||
                !((!id && !!password && password === re_password) || id)
              }
              onClick={onRegUser}
            >
              {isSaving && (
                <Spinner
                  animation="border"
                  variant="secondary"
                  size="sm"
                  className="me-1"
                />
              )}
              Save
            </button>
            <button
              className="bg-white rounded-2 px-3 ms-2"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              close
            </button>
          </Card.Footer>
        </Card>
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex mb-2 justify-content-between">
        <div className="d-flex">
          <h6 className="text-muted mb-0">Total Admins:</h6>
          <h6 className="ms-1 mb-0">{users?.length}</h6>
        </div>
        {(userinfo?.level || 0) >= AdminLevel.master && (
          <div>
            <button
              className="bg-white rounded-2 px-3"
              onClick={() => {
                reset();
                setIsEdit(true);
              }}
            >
              add administrator
            </button>
          </div>
        )}
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">username</th>
            <th scope="col">email</th>
            <th scope="col">created time</th>
            <th scope="col">updated time</th>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((it, idx) => (
              <tr key={idx}>
                {/* <td>{idx + 1}</td> */}
                <td>
                  {it.firstname} {it.lastname}
                </td>
                <td>{it.email}</td>

                <td>{renderTime(it.createdDate)}</td>
                <td>{renderTime(it.modifiedDate)}</td>
                <td>
                  {(userinfo?.level || 0) >= AdminLevel.master && (
                    <button
                      type="button"
                      className="bg-white rounded-2 px-3 ms-2"
                      onClick={() => onEditItem(it)}
                    >
                      edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* <ConfirmModal
        show={showConfirm}
        content={showMessage}
        leftTitle={'no'}
        rightTitle={'yes'}
        onOk={() => {
          setShowConfirm(false);
        }}
        onDiscard={() => {
          afterDiscard();
        }}
      /> */}
      {addUsers && (
        <AddUserModal
          show={addUsers}
          onOk={() => {
            setAddUsers(false);
          }}
          onConfirm={() => {
            setAddUsers(false);
            getUsers();
          }}
        />
      )}
    </div>
  );
};
