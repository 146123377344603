export interface Admin {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  types: string;
  accessRange: string;
  createdDate: string;
  modifiedDate: string;
  status: boolean;
  level: number;
}

export enum AdminLevel {
  common,
  master,
  super,
}
